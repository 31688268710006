import axios from 'axios'

export default {
  //查询运维统计
  getOpsCount(){
    return axios.get('/show/ops/opsCount');
  },
  //查询工单统计
  getSheetCount(){
    return axios.get('/show/ops/sheetCount');
  },
  //查询业务占比
  getBusiness(){
    return axios.get('/show/ops/business');
  },
  //查询运维队伍出勤排名-本月
  getThisMonthAttendance(){
    return axios.get('/show/ops/thisMonthAttendance');
  },
  //查询运维中心架构
  getOpsFramework(){
    return axios.get('/show/ops/opsFramework');
  },
  //查询客户统计
  getCompanyDevCount(){
    return axios.get('/show/safe/getCompanyDevCount');
  },
  //查询服务价值
  getServiceCost(){
    return axios.get('/show/ops/serviceCost');
  },
  //查询车辆统计
  getCarList(){
    return axios.get('/show/ops/carList');
  },
}
