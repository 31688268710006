<template>
  <div class="mainbox">
    <div class="left">
      <div class="modulebox f_column">
        <div class="title"><span>客户统计</span></div>
        <div class="content customer">
          <div class="list" v-for="(item,index) in customerlist" :key="index">
            <div class="top"><b>{{item.value}}</b>{{item.dw}}</div>
            <div class="text">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>运维统计</span></div>
        <div class="content operation">
          <div class="list" v-for="(item,index) in operationlist" :key="index">
            <div class="top"><b>{{item.value}}</b>{{item.dw}}</div>
            <div class="text">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>工单统计</span></div>
        <div class="content workorder">
          <div class="list" v-for="(item,index) in workorderlist" :key="index">
            <div class="top"><b>{{item.value}}</b>{{item.dw}}</div>
            <div class="text">{{item.title}}</div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>运维队伍出勤排名-本月</span></div>
        <div class="content ">
          <div ref="bar1" class="echarts"></div>
        </div>
      </div>
    </div>
    <div class="right">
      <div class="modulebox">
        <div class="title"><span>服务价值</span></div>
        <div class="content servicevalue">
          <div class="list" v-for="(item,index) in servicevaluelist" :key="index">
            <p>{{item.title}}</p>
            <p><b>{{item.value}}</b>{{item.dw}}</p>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="f_row">
          <div class="f_column" style="margin-right:10px">
            <div class="title"><span>业务占比-本年</span></div>
            <div class="content ">
              <div ref="pie1" class="echarts"></div>
            </div>
          </div>
          <div class="f_column">
            <div class="title"><span>车辆类型统计-本年</span></div>
            <div class="content ">
              <div ref="bar2" class="echarts"></div>
            </div>
          </div>
        </div>
      </div>
      <div class="modulebox">
        <div class="title"><span>运维中心架构</span></div>
        <div class="content jglist">
          <div class="li" v-for="(item,index) in centerArchList" :key="index">
            <div class="tit">{{item.deptName}}</div>
            <div class="text">
              <p><span>公司名称</span><b>{{item.companyName}}</b></p>
              <p><span>负责人</span><b>{{item.deptHandler}}</b></p>
              <p><span>人员数量</span><b>{{item.user}}人</b></p>
              <p><span>覆盖范围</span><b>{{item.manageRangeName}}</b></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
let echarts = require('echarts');
import api from '@/apis/IntelligenceOps/intel'
import { compare }  from '@/assets/js/common'
import common from '@/components/monitorCommon/common'
export default {
  components:{
  },
  data() {
    return {
      customerlist:[
        {id:0,title:'服务企业数',dw:'家',value:0},
        {id:1,title:'运维设备数',dw:'台',value:0},
        {id:2,title:'服务城市',dw:'个',value:0},
        {id:3,title:'服务行业',dw:'个',value:0},
        {id:4,title:'服务产业',dw:'个',value:0},
      ],
      operationlist:[
        {id:0,title:'运维队伍',dw:'个',value:0},
        {id:1,title:'工程车辆',dw:'台',value:0},
        {id:2,title:'运维人员',dw:'人',value:0},
        {id:3,title:'服务站',dw:'个',value:0},
        {id:4,title:'应急后备人员',dw:'人',value:0},
      ],
      workorderlist:[
        {id:0,title:'今日工单',dw:'个',value:0},
        {id:1,title:'合同新装',dw:'台',value:0},
        {id:2,title:'例行巡检',dw:'次',value:0},
        {id:3,title:'设备维修',dw:'次',value:0},
        {id:4,title:'报警',dw:'次',value:0},
        {id:5,title:'其他工单',dw:'个',value:0},
      ],
      servicevaluelist:[
        {id:0,title:'检修设备',dw:'次',value:0},
        {id:1,title:'排查生产安全隐患',dw:'处',value:0},
        {id:2,title:'降低损耗',dw:'',value:'0%'},
        {id:3,title:'技术改造项目',dw:'个',value:0},
        {id:4,title:'节约运维费用',dw:'万元',value:0},
        {id:5,title:'减少经济损失',dw:'万元',value:0},
        {id:6,title:'提升用能质量',dw:'',value:'0%'},
        {id:7,title:'技改经济价值',dw:'万元',value:0},
      ],
      centerArchList:[
        {
          deptName:'--',
          user:'--',
          companyName:'--',
          deptHandler:'--',
          manageRangeName:'--',
        },
      ],
    };
  },

  methods:{
    //获取车辆统计
    getCarNumber(){
      api.getCarList().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            let _arr = [
              res.data.van,
              res.data.truck,
              res.data.hoist,
              res.data.energyStorage,
            ]
            this.bar2(_arr)
          }else{
            this.bar2([0,0,0,0])
          }
        }else{
          this.$message.error('获取车辆统计失败');
          return;
        }
      })
    },
    //获取运维中心架构
    getOkrTreeData(){
      api.getOpsFramework().then(res=>{
        if(res.code==10000){
          if(res.data){
            this.centerArchList = res.data
          }
        }else{
          this.$message.error('获取中心架构失败');
          return;
        }
      })
    },
    //获取服务价值
    getServiceVal(){
      api.getServiceCost().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            this.servicevaluelist[0].value = res.data.opsDev
            this.servicevaluelist[1].value = res.data.checkSafety
            this.servicevaluelist[2].value = res.data.reduceLoss+'%'
            this.servicevaluelist[3].value = res.data.transformation
            this.servicevaluelist[4].value = (res.data.maintenanceCosts/10000).toFixed(2)
            this.servicevaluelist[5].value = (res.data.economicLosses/10000).toFixed(2)
            this.servicevaluelist[6].value = res.data.energyQuality+'%'
            this.servicevaluelist[7].value = (res.data.technological/10000).toFixed(2)
          }
        }
      })
    },
    //获取客户统计
    getUserCount(){
      api.getCompanyDevCount().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            this.customerlist[0].value = res.data.company
            this.customerlist[1].value = res.data.devSize
            this.customerlist[2].value = res.data.city
            this.customerlist[3].value = res.data.trade
            this.customerlist[4].value = res.data.service
          }
        }
      })
    },
    //获取运维队伍出勤排名
    getRank(){
      api.getThisMonthAttendance().then(res=>{
        if(res.code==10000){
          if(res.data){
            this.bar1(res.data)
          }else{
            this.bar1([])
          }
        }
      })
    },
    //获取业务占比
    getBusZB(){
      api.getBusiness().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            this.pie1(
              res.data.inspect,
              res.data.dev,
              res.data.devRepair
            )
          }
        }
      })
    },
    //获取左二层运维统计
    getOpsNumber(){
      api.getOpsCount().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            this.operationlist[0].value = res.data.company
            this.operationlist[1].value = res.data.car
            this.operationlist[2].value = res.data.opsUser
            this.operationlist[3].value = res.data.serviceTrain
            this.operationlist[4].value = res.data.emergency
          }
        }
      })
    },
    //获取左三层工单统计
    getSheetNumber(){
      api.getSheetCount().then(res=>{
        if(res.code==10000){
          if(res.data&&JSON.stringify(res.data) != "{}"){
            this.workorderlist[0].value = res.data.total
            this.workorderlist[1].value = res.data.dev
            this.workorderlist[2].value = res.data.inspect
            this.workorderlist[3].value = res.data.devRepair
            this.workorderlist[4].value = res.data.alarm
            this.workorderlist[5].value = res.data.other
          }
        }
      })
    },
    bar1(vals){
      let bar1Chart = echarts.init(this.$refs.bar1);
      common.moveEcharts(vals.length,bar1Chart)
      let _arr = vals.sort(compare('sum',0))
      let _name = []
      let _sum = []
      for(var i in _arr){
        _name.push(_arr[i].companyStructName)
        _sum.push(_arr[i].sum)
      }
			bar1Chart.setOption({
        color:['#b486f3'],
        title: {
          show: vals.length == 0,
          textStyle: {
            color: "#fff",
            fontSize: 20,
          },
          text: "暂无数据",
          left: "center",
          top: "center",
        },
        tooltip: {
          trigger: 'axis', backgroundColor:'rgba(0,0,0,.8)',borderColor:'#000',
          textStyle:{ color: "#fff",fontSize: 20},
          axisPointer: {
            type: 'shadow' 
          }
        },
        grid: {
            left: '18%',right: '6%', bottom: '6%', top:'3%'
        },
        xAxis: {
          type: 'value',show:false
        },
        yAxis: {
          type: 'category', axisLine:{ show:false}, axisTick:{ show:false},
          axisLabel: { color:'#fff',fontSize: 20},
          data:_name
        },
        series: [
          {
            name: '次数', type: 'bar', stack: 'total', barWidth:'35%',
            label: {
              show: true,fontSize: 20,color:'#333',fontWeight:600,offset:[0,2],
            },
            data: _sum
          },
        ]
			})
    },
    pie1(a,b,c) {
			let pie1Chart = echarts.init(this.$refs.pie1)
      common.moveEcharts(3,pie1Chart)
			pie1Chart.setOption({
        color:['#005ae4','#00ffcc','#eb6098'],
				series: [
          {
            type: 'pie',
            radius: ['0', '60%'],
            itemStyle: {
              borderColor: '#050f5a',
              borderWidth: 2
            },
            label: {
              show: true, color:'#ccd0f2',fontSize: 20,
              formatter: '{b}\n{v|{d} %}',
              rich: {
                v: {
                  fontSize: 20,lineHeight:30,fontWeight:600,
                  color: '#fff'
                }
              }
            },
            labelLine:{
              length:20,length2:40,
              lineStyle:{ color:'#ccd0f2',width:2}
            },
            data: [
              { value: a?a:0, name: '例行巡检' },
              { value: b?b:0, name: '设备新装' },
              { value: c?c:0, name: '抢修抢险' },
            ]
          }
        ]
			})
		},
    bar2(arr){
      let bar2Chart = echarts.init(this.$refs.bar2);
      common.moveEcharts(arr.length,bar2Chart)
			bar2Chart.setOption({
        color:['#ffff00'],
        grid: {
            left: '6%',right: '6%', bottom: '18%', top:'6%'
        },
        xAxis: {
            type: 'category', 
            data: ['面包车','货车','起重车','应急储能车'],
            axisLine: {
              lineStyle: { color: '#3570ff' }
            },
            axisLabel: {  color:'#fff',fontSize: 20,lineHeight:40}
        },
        yAxis: {
            minInterval: 1,
            type: 'value', 
            axisLine: {
              show:true,
              lineStyle: {
                color: '#3570ff'
              }
            },
            splitLine: { show: false},
            axisLabel: { show:true}
        },
        series: [{
            type: 'bar',barWidth: '22%',
            label: {
              show: true, position: 'top',
              textStyle:{ color:'#fff', fontSize: 20}
            },
            data: arr
          }
        ]
			})
    },

  },
  mounted() {
    this.getOpsNumber();//获取左二层运维统计
    this.getSheetNumber();//获取左三层工单统计
    this.getBusZB();//获取业务占比
    this.getRank();//获取运维队伍出勤排名
    this.getUserCount();//获取客户统计
    this.getServiceVal();//获取服务价值
    this.getOkrTreeData();//获取运维中心架构
    this.getCarNumber();//获取车辆统计
  },
}
</script>

<style lang="less" scoped>
.mainbox{ position: absolute; width: 100%; height: 100%; top:0; left:0; padding:70px 60px;
  overflow: hidden;
  &>.left{ width: 1360px; height: 2020px; position: absolute; z-index: 8; top:70px; left:60px;
    .modulebox{ flex: auto;}
    .modulebox:nth-child(1){ height: 14rem; flex-shrink: 0; margin-top: 0;}
    .modulebox:nth-child(2){ height: 14rem; flex-shrink: 0;}
    .modulebox:nth-child(3){ height: 14rem; flex-shrink: 0;}
    .modulebox:nth-child(4){ height: 24rem; flex-shrink: 0;}
  }
  &>.right{ width: 1360px; height: 2020px; position: absolute; z-index: 8; top:70px; right:60px;
    .modulebox{ flex: auto;}
    .modulebox:nth-child(1){ height: 16rem; flex-shrink: 0; margin-top: 0;}
    .modulebox:nth-child(2){ height: 17rem; flex-shrink: 0;
      .f_row{
        .f_column{ width: 50%; flex-shrink: 0;}
      }
    }
    .modulebox:nth-child(3){ height: 32rem; flex-shrink: 0;}
  }

  .customer{ display: flex; flex-direction: row; padding:0 .6rem .6rem;
    .list{ flex: 1; margin:.5rem; border-radius: 10px; border: #005ae4 solid 1px; overflow: hidden; display: flex; flex-direction: column;
      .top{ flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;
        b{ font-size: 1.8rem; font-weight: 500; line-height: 1; color: #00ffcc;}
      }
      .text{ height: 3rem; line-height: 3rem; color: #fff; text-align: center; background: #05238f;}
    }
  }
  .operation{ display: flex; flex-direction: row; padding:.4rem 1rem 1rem;
    .list{ flex: 1; margin:.5rem; border-radius: 10px; overflow: hidden; display: flex; flex-direction: column; align-items: center;
      .top{ width: 6.6rem; height: 6.6rem; position: relative; border-radius: 50%; border: #005ae4 solid 6px; display: flex; flex-direction: column; align-items: center; justify-content: center;
        b{ font-size: 1.8rem; font-weight: 500; color: #ffff00; line-height: 1; padding-top: 15px;}
      }
      .top::after{ content:' '; position: absolute; bottom: 0; width: 0; height: 0; border-style: solid; border-width: 16px; border-color: transparent transparent #ffff00 transparent;}
      .text{ height: 3rem; line-height: 2.2rem; color: #fff;}
    }
  }
  .workorder{ display: flex; flex-direction: row; padding:0 .6rem .6rem;
    .list{ flex: 1; margin:.5rem; border-radius: 10px; background: rgba(255,255,255,.1); overflow: hidden; display: flex; flex-direction: column;
      .top{ flex: 1; display: flex; flex-direction: column; align-items: center; justify-content: center;
        b{ font-size: 1.8rem; font-weight: 500; line-height: 1; color: #00ffcc;}
      }
      .text{ height: 3rem; color: #fff; text-align: center;}
    }
  }
  .servicevalue{ display: flex; flex-direction: row; flex-wrap: wrap; padding-top: .4rem; justify-content: space-evenly;
    .list{ width: 22.5%; height: 41%; border: #00ffcc dashed 1px; display: flex; flex-direction:column; justify-content: center; padding-left: 1rem;
      p:nth-child(1){ margin-bottom: 10px;}
      b{ font-size: 1.6rem; margin-right: 10px; font-weight: 500; line-height: 1; color: #00ffcc;}
    }
  }
  .jglist{ overflow-y: auto; padding:.4rem .4rem 1rem; display: flex; flex-direction: row; flex-wrap: wrap;
    .li{ border: #005ae4 solid 1px; border-radius: .6rem; width: 48%; height: 12.8rem; margin:1%; display: flex; flex-direction: column;
      .tit{ height: 2.6rem; line-height: 2.6rem; overflow: hidden; text-align: center; font-size: 1rem; font-weight: bold; color: #00ccff; background: rgba(255,255,255,.1);}
      .text{ padding:0 1rem; flex: 1; overflow: hidden; display: flex; flex-direction: column; justify-content:space-evenly;
        p{ display: flex; flex-direction: row;
          span{ width: 6rem; text-align: right; margin-right: .4rem;}
          b{ color: #00ffcc; font-weight: normal; flex: 1; text-overflow: ellipsis; white-space: nowrap; overflow: hidden;}
        }
      }
    }
  }



}
</style>
